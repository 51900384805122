import * as React from 'react';

import './AlertBlock.scss';

import { IconBTAlert } from '../../Atoms/Icons/Icons';

const AlertBlock = ({ label, link, icon, directopen, onClick }) => {
  let cleanHTMLLabel = label.replace(/<div>/g, '').replace(/<\/div>/g, '');
  if (cleanHTMLLabel.indexOf('<p>') < 0) {
    cleanHTMLLabel = '<p>' + cleanHTMLLabel + '</p>';
  }
  return (
    <a className="alert_block" href={link} onClick={onClick && (() => onClick(directopen))}>
      {
        icon ? (
          icon
        ) : (
          <IconBTAlert />
        )
      }
      <div dangerouslySetInnerHTML={{ __html: cleanHTMLLabel }}></div>
    </a>
  );
};

AlertBlock.defaultProps = {
  label: 'Default Label',
  link: '#'
};

export default AlertBlock;
